import React, { useState } from "react"
import { Button, Modal, Carousel } from "react-bootstrap"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const lab1 = require("../../images/lab1.jpg")
const lab2 = require("../../images/lab2.jpg")

const LaboratoryPage = () => {
  const [showCarousel, setShowCarousel] = useState(false)

  return (
    <Layout>
      {/* Carousel Modal for Popup Images */}
      <Modal
        show={showCarousel}
        onHide={() => setShowCarousel(false)}
        size="xl"
      >
        <div style={{ maxWidth: "1200px" }}>
          <Carousel controls fade>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={lab1}
                alt="First slide"
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={lab2}
                alt="Second slide"
                style={{
                  objectFit: "cover",
                  objectPosition: "bottom",
                  height: "90vh",
                }}
              />
            </Carousel.Item>
          </Carousel>
        </div>
      </Modal>
      <SEO title="Laboratory" description="" lang="en" meta={[]} />

      {/* Page Content */}
      <section className="lab-page">
        <div className="px-5">
          <h1>Laboratory</h1>

          <h2>Research in Molecular Science</h2>

          <p className="my-4" style={{ maxWidth: "400px" }}>
            Drug discovery and population-wide study for a growing African
            population
          </p>
          <div className="button-div">
            <Button variant="secondary" onClick={() => setShowCarousel(true)}>
              Look Inside
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LaboratoryPage
